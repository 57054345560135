import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ProjectClient from '../../api/projectClient';

import { updateVideoSource } from '../../helpers/projectHelper';

const initialState = {
	isEdits: false,
	isApprove: false,
	editorProject: null,
	videoSource: null,
	currentTime: 0,
	duration: 0,
	offset: 0,
	isOffsetSlider: false,
};

const projectClient = new ProjectClient();

export const getEditorProject = createAsyncThunk(
	'editorProject/getEditorProject',
	async (projectId) => {
		const project = await projectClient.getShare(projectId);
		const projectVideoSource = await updateVideoSource(projectId, project.renderKey, project.videoType);

		return {
			project,
			projectVideoSource
		};
	}
);

const clientEditorSlice = createSlice({
	name: 'clientEditor',
	initialState,
	reducers: {
		setIsEdits: (state, action) => {
			state.isEdits = action.payload;
		},
		setCurrentTime: (state, action) => {
			state.currentTime = action.payload;
		},
		setDuration: (state, action) => {
			state.duration = action.payload;
			state.offset = action.payload;
		},
		setOffset: (state, action) => {
			state.offset = action.payload;
		},
		setIsOffsetSlider: (state, action) => {
			state.isOffsetSlider = action.payload;
		},
		setIsApprove: (state, action) => {
			state.isApprove = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getEditorProject.pending, (state) => {
			state.editorProject = null;
		});
		builder.addCase(getEditorProject.fulfilled, (state, action) => {
			state.editorProject = action.payload.project;
			state.videoSource = action.payload.projectVideoSource;
		});
		builder.addCase(getEditorProject.rejected, (state) => {
			state.editorProject = null;
		});
	}
});

export const clientEditorActions = clientEditorSlice.actions;

export default clientEditorSlice.reducer;
