import { Config } from '../config';

const refreshPage = () => {
	window.location.reload();
};

const updateVideoSource = (share, renderKey, videoType) => {
	let videoUrl = videoType === 'original'
		? `${Config.host}/storage/share/${share}/stream`
		: `${Config.host}/storage/share/${share}/previewstream`;

	if (renderKey != null) {
		const url = new URL(videoUrl);

		url.searchParams.set("key", renderKey);
		videoUrl = url.toString();
	}

	return videoUrl;
};

export {
	updateVideoSource,
	refreshPage
};
