import { Outlet } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

function MainLayout() {
	return (
		<Row className="mb-auto">
			<Col
				className="my-3"
				xxl={{ span: 6, offset: 3 }}
				xl={{ span: 8, offset: 2 }}
				lg={{ span: 8, offset: 2 }}
				md={{ span: 10, offset: 1 }}
				sm={{ span: 10, offset: 1 }}
				xs={{ span: 12 }}
			>
				<Outlet />
			</Col>
		</Row>
	);
}

export default MainLayout;
