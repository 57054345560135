import { useState, useEffect, useCallback, useMemo } from 'react';

import SubtitlesClient from '../api/subtitlesClient';
import ProjectClient from '../api/projectClient';

const useSilenceAccelerator = ({ id, updateProject }) => {
	const [silenceRenderId, setSilenceRenderId] = useState(null);
	const [silenceLoader, setSilenceLoader] = useState(false);

	const subtitlesClient = useMemo(() => (new SubtitlesClient()), []);
	const projectClient = useMemo(() => (new ProjectClient()), []);

	const addSilenceAccelerator = useCallback(async () => {
		const res = await subtitlesClient.addSilenceAccelerator(id);

		if (res.message?.renderId) {
			setSilenceLoader(true);
			setSilenceRenderId(res.message?.renderId);
		} else {
			setSilenceLoader(false);

			console.log(res);
		}
	}, [id, subtitlesClient]);

	const handleCheckSilenceAccelerator = useCallback(async () => {
		let res = await subtitlesClient.getSubtitles(silenceRenderId);

		if (res?.currentStatus !== 5) {
			setTimeout(handleCheckSilenceAccelerator, 5000);
		} else {
			let res = await projectClient.get(id);
			await updateProject(res, true);
			
			setSilenceLoader(false);
		}
	}, [subtitlesClient, silenceRenderId, projectClient, id, updateProject]);

	useEffect(() => {
		if (silenceLoader) {
			handleCheckSilenceAccelerator();
		}
	}, [handleCheckSilenceAccelerator, silenceLoader]);

	return {
		silenceLoader,
		addSilenceAccelerator,
	};
};

export default useSilenceAccelerator;