import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Navbar from '../components/navbar';

function Layout() {
	return (
		<div className="page_background">
			<header>
				<Navbar />
			</header>
			<main className="flex-shrink-0 mt-5">
				<Container fluid>
					<Outlet />
				</Container>
			</main>
		</div>
	);
}

export default Layout;