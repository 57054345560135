import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputValidate from '../../components/input/InputValidate';
import { FormErrors } from '../../components/input/FormErrors';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';

import { UserProfile } from '../../contracts/userProfile';

import { changeUserInfo, userActions } from '../../store/slices/userSlice';

function ProfileForm() {
	const {
		register: registerAccountSettings,
		handleSubmit: handleSubmitAccountSettings,
		formState: { errors: accountSettingsFormErrors },
		setValue: accountSettingsSetValue,
		getValues
	} = useForm();

	const [isChanged, setIsChanged] = useState(false);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const userState = useSelector((state) => state.user.profile);
	const accountSettingsErrors = useSelector((state) => state.user.errorMessages);
	const isAccountSettingsSuccess = useSelector((state) => state.user.isAccountSettingsSuccess);

	const hasChanges = (formData) => {
		return (
			formData.firstName !== userState.firstName ||
			formData.lastName !== userState.lastName ||
			formData.phoneNumber !== userState.phoneNumber
		);
	};

	const onSubmitAccountSettings = async (data) => {
		if (!hasChanges(data)) return;

		const userUpdate = new UserProfile();

		userUpdate.firstName = data.firstName;
		userUpdate.lastName = data.lastName;
		userUpdate.phoneNumber = data.phoneNumber;

		const requsetData = {
			userId: userState.userId,
			userData: userUpdate
		};

		dispatch(userActions.resetErrorMessages());
		dispatch(changeUserInfo(requsetData));
	};

	const handleChangeAccount = () => {
		const currentValues = getValues();
		
		setIsChanged(hasChanges(currentValues));

		dispatch(userActions.resetErrorMessages());
		dispatch(userActions.changeAccountSettingsSuccess());
	};

	const getProfileData = useCallback(() => {
		accountSettingsSetValue("firstName", userState.firstName);
		accountSettingsSetValue("lastName", userState.lastName);
		accountSettingsSetValue("phoneNumber", userState.phoneNumber);
	}, [userState, accountSettingsSetValue]);

	useEffect(() => {
		if (!userState) return;

		getProfileData();
	}, [getProfileData, userState]);

	return (
		<form
			className="row g-3"
			onSubmit={handleSubmitAccountSettings(onSubmitAccountSettings)}
			onChange={handleChangeAccount}
		>
			<div className="col-6">
				<InputValidate
					label={t("firstName")}
					name="firstName"
					register={registerAccountSettings}
				/>
			</div>
			<div className="col-6">
				<InputValidate
					label={t("secondName")}
					name="lastName"
					register={registerAccountSettings}
				/>
			</div>
			<div className="col-12">
				<InputValidate
					label={t("phone")}
					name="phoneNumber"
					register={registerAccountSettings}
					errors={accountSettingsFormErrors}
					validation={{
						pattern: {
							value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
							message: t("wrongFormat")
						}
					}}
				/>
			</div>
			<FormErrors
				errors={accountSettingsErrors}
				successful={isAccountSettingsSuccess}
				defaultMessage={t("saveSuccess")}
			/>
			<div className="d-flex justify-content-center gap-2">
				<ButtonSubmit disabled={!isChanged} text={t("save")} />
			</div>
		</form>
	);
}

export default ProfileForm;
