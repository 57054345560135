import { useState, useEffect, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListGroup from 'react-bootstrap/ListGroup';
import Card from '../../components/card';
import ProjectListItem from './ui.item';

import ProjectClient from '../../api/projectClient';

export default function ProjectList() {
	const [items, setItems] = useState([]);
	
	const { t } = useTranslation();
	
	const projectClient = useMemo(() => new ProjectClient(), []);

	const getAllProjects = useCallback(async () => {
		let arr = [];
		let res = await projectClient.getAll();

		if (!res.length) return;

		res.forEach(item => {
			arr.push(new ProjectListItem(item));
		});

		setItems(arr);
	}, [projectClient]);

	useEffect(() => {
		getAllProjects();
	}, [getAllProjects]);

	return (
		<Card>
			<div className="link text-secondary d-flex align-items-center justify-content-between">
				<h3>{t("yourProjects")}</h3>
				<NavLink className="btn btn-outline-info" to="/project">
					{t("addFiles")}
				</NavLink>
			</div>
			<ListGroup>{items}</ListGroup>
		</Card>
	);
}
