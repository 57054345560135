import { configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import userSlice from './slices/userSlice';
import clientEditorSlice from '../widgets/clientEditor/reducer';

import AuthHelper from '../helpers/authHelper';

const authHelper = new AuthHelper();

export const store = configureStore({
	reducer: {
		auth: authSlice,
		user: userSlice,
		clientEditor: clientEditorSlice
	}
});

store.subscribe(() => {
	authHelper.setToken(store.getState().auth.jwt);
});
