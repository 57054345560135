import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectContext } from '../../context/projectContext';

import { getPeriodText } from '../../helpers/timeHelper';

function ProjectStatus() {
	const { t } = useTranslation();

	const context = useContext(ProjectContext);

	const { project, qpos } = context?.render;

	return (
		(qpos > 0 && project?.lastActive) && (
			<span
				className="badge rounded-pill bg-warning"
				data-status={project.status}
			>
				{t("queue")}: {getPeriodText(project.lastActive)}
			</span>
		)
	);
}

export default ProjectStatus;
