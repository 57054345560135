import { ListGroup } from 'react-bootstrap';
import StatusBadge from '../../components/statusBadge';

function ProjectListItem(project) {
	return (
		<ListGroup.Item
			key={project.id}
			className="d-flex w-100 justify-content-between bg-transparent border-0 link-opacity-10-hover px-0"
			action href={`project/${project.id}`}
		>
			<div className="d-flex w-100 justify-content-between">
				<h5 className="text-secondary">{project.name}</h5>
				<div>
					<StatusBadge project={project}/>
				</div>
			</div>
		</ListGroup.Item>
	);
}

export default ProjectListItem;