import './index.scss';

import { useRef, useContext } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ProjectPlayerTab from '../projectPlayerTab';
import VideoPlayer from '../../components/videoPlayer';

import { ProjectContext } from '../../context/projectContext';

function ProjectPlayer() {
	const carouselRef = useRef(null);

	// const [carouselIndex, setCarouselIndex] = useState(0);

	const context = useContext(ProjectContext);

	const { project, videoSource, videoPreviewSource } = context?.render;
	const { isVerticalCheck } = context?.controls;
	const { videoRef, videoRefPreview, currentVideoType, setCurrentVideoType } = context?.video;
	const { subtitlesUrl, updateSubtitles } = context?.subtitle;

	function handleChange(selectedIndex) {
		// setCarouselIndex(selectedIndex);
		if (!selectedIndex && videoRefPreview.current) {
			videoRefPreview.current.getInternalPlayer().pause();
			setCurrentVideoType("original");
		} else if (videoRef.current) {
			videoRef.current.getInternalPlayer().pause();
			setCurrentVideoType("preview");
		}
	}

	return (
		project?.status === 4 && (
			<>
				{
					videoPreviewSource ? (
						<div className="col-12">
							<ProjectPlayerTab
								carouselRef={carouselRef}
								currentVideoType={currentVideoType}
							/>
							<Carousel
								ref={carouselRef}
								onChange={handleChange}
								showThumbs={false}
								showStatus={false}
								showArrows={false}
								showIndicators={false}
							>
								<VideoPlayer
									videoId="generalVideoID"
									src={videoSource}
									videoRef={videoRef}
									subtitlesUrl={subtitlesUrl}
									updateSubtitles={updateSubtitles}
									isVertical={isVerticalCheck}
								/>
								<VideoPlayer
									videoId="previewVideoID"
									src={videoPreviewSource}
									videoRef={videoRefPreview}
									isVertical={isVerticalCheck}
								/>
							</Carousel>
						</div>
					) : (
						<div className="col-12">
							<VideoPlayer
								videoId="generalVideoID"
								src={videoSource}
								videoRef={videoRef}
								subtitlesUrl={subtitlesUrl}
								updateSubtitles={updateSubtitles}
								isVertical={isVerticalCheck}
							/>
						</div>
					)
				}
			</>
		)
	);
}

export default ProjectPlayer;