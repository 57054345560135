import { useTranslation } from 'react-i18next';

function FormErrors({ errors, formErrors, successful, defaultMessage }) {
	const { t } = useTranslation();

	if (successful) {
		return (
			<div className="d-flex  valid-feedback" role="alert">
				{defaultMessage}
			</div>
		);
	}

	return (
		errors != null && Object.keys(errors).length > 0 ?
			<div>
				{
					Object.keys(errors).map((key, index) => {
						return (
							<div key={key} className="d-flex invalid-feedback" role="alert">
								{t(Object.keys(errors)[index] + "Error")}
							</div>
						);
					})
				}
			</div>
		:
			null
	);
}

export { FormErrors };
