import { Config } from '../config';

class AuthClient {
	/**
	 * Авторизация пользователя
	 * @param {{email: string, password: string}} Login - email, password
	 * @returns {string} jwt.token
	 */

	async login(login) {
		try {
			const res = await fetch(`${Config.authHost}/login`, {
				method: "Post",
				body: JSON.stringify(login), 
				headers: {
					"Content-Type": "application/json"
				}
			});

			return await res.json();
		} catch (error) {
			console.error(error);
			
			throw error;
		}
	}

	/**
	 * Регистрация пользователя
	 * @param {Object} Register - параметры регистрации
	 * @param {string} Register.email - почта
	 * @param {string} Register.password - пароль
	 * @param {string} Register.firstName - имя
	 * @param {string} Register.lastName - фамилия
	 * @param {string} Register.phoneNumber - номер телефона
	 * @returns {string} user.id 
	 */

	async register(register) {
		try {
			const res = await fetch(`${Config.authHost}/register`, {
				method: "Post", 
				body: JSON.stringify(register), 
				headers: {
					"Content-Type": "application/json"
				}
			});

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default AuthClient;
