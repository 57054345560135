import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import RenderClient from '../../api/renderClient';

import { ProjectContext } from '../../context/projectContext';

import { timeSpanToTime } from '../../helpers/timeHelper';

function ProjectProcessing() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const context = useContext(ProjectContext);

	const { id, project, qpos } = context?.render;

	const renderClient = new RenderClient();

	const cancelProject = async () => {
		await renderClient.cancel(id);

		navigate(`/`);
	};

	return (
		project?.status === 3 && (
			<div>
				{
					qpos === 0 && (
						<div>
							<div className="my-3 text-secondary">
								{t("processing")} &nbsp;
								{
									project.remainingTime && (
										<>
											{t("timeLeft")}{" "}
											{timeSpanToTime(project.remainingTime)}
										</>
									)
								}
							</div>
							<div
								className="progress my-3"
								role="progressbar"
								aria-label=""
								aria-valuenow={
									project.percentReady ? (project.percentReady * 100).toFixed(2) : 0
								}
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<div
									className="progress-bar progress-bar-striped progress-bar-animated"
									style={{
										width: `${project.percentReady ? project.percentReady * 100 : 0}%`
									}}
								>
									{project.percentReady ? (project.percentReady * 100).toFixed(2) : 0}%
								</div>
							</div>
						</div>
					)
				}
				<div className="d-grid gap-2">
					<Button
						variant={"outline-danger"}
						size="lg"
						onClick={cancelProject}
					>
						{t("cansel")}
					</Button>
				</div>
			</div>
		)
	);
}

export default ProjectProcessing;