import { Config } from '../config';
import AuthHelper from '../helpers/authHelper';

class ShareClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Получить общедоступное видео
	 * 
	 * @param {string} projectId - id проекта
	 * @param {string} videoType - тип проекта (original, preview)
	 * @returns {Object} - объект с видео
	 */
	async get(projectID, videoType) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/share/${projectID}/${videoType}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);

			const text = await res.text();
	
			if (!text) return null;
	
			const json = JSON.parse(text);
	
			return json;
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Создать общедоступное видео
	 * 
	 * @param {string} projectId - id проекта
	 * @param {string} videoType - тип проекта (original, preview)
	 * @returns {Object} - объект с видео
	 */
	async create(projectId, videoType) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/share/${projectId}/${videoType}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);
			
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Удалить общедоступное видео
	 * 
	 * @param {string} projectId - id проекта
	 * @param {string} videoType - тип проекта (original, preview)
	 * @returns {null}
	 */
	async remove(projectId, videoType) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/share/${projectId}/${videoType}`, {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return null;
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default ShareClient;