import { Button } from "react-bootstrap";

function ButtonSubmit(props) {
	return (
		<Button
			className="btn-width"
			variant="outline-info"
			size="lg"
			onClick={props.onClick}
			type="submit"
			disabled={props.disabled}
		>
			{props.text}
		</Button>
	);
}

export { ButtonSubmit };