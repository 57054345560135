import { useState, useEffect, useCallback, useMemo } from 'react';

import ProjectClient from '../api/projectClient';
import SubtitlesClient from '../api/subtitlesClient';

const useSubtitleProject = ({ id, updateProject }) => {
	const [subtitleRenderId, setSubtitleRenderId] = useState(null);
	const [subtitleLoader, setSubtitleLoader] = useState(false);
	const [subtitleCutLoader, setSubtitleCutLoader] = useState(false);
	const [subtitleSegments, setSubtitleSegments] = useState([]);
	const [subtitleCheck, setSubtitleCheck] = useState(true);
	const [subtitleRender, setSubtitleRender] = useState(false);
	const [modifiedSegments, setModifiedSegments] = useState([]);
	const [subtitlesUrl, setSubtitlesUrl] = useState(null);

	const projectClient = useMemo(() => (new ProjectClient()), []);
	const subtitlesClient = useMemo(() => (new SubtitlesClient()), []);

	const addSubtitles = useCallback(async () => {
		const res = await subtitlesClient.addSubtitles(id);
		
		if (res.message?.renderId) setSubtitleLoader(true);
		else return;

		setSubtitleRenderId(res.message?.renderId);
	}, [id, subtitlesClient]);

	const updateSubtitles = useCallback(async () => {
		try {
			const webVttText = await subtitlesClient.getSubtitlesWebVtt(id);

			if (webVttText) {
				const blob = new Blob([webVttText], { type: 'text/vtt' });
				const url = URL.createObjectURL(blob);
								
				setSubtitlesUrl(url);
			} else {
				console.log("Субтитры не найдены");
			}
		} catch (error) {
			console.log("Ошибка при получении субтитров:", error);
		}
	}, [id, subtitlesClient]);

	const getSubtitles = useCallback(async () => {
		let res = await subtitlesClient.getSubtitles(subtitleRenderId);

		if (res?.currentStatus !== 4) {
			setTimeout(getSubtitles, 10000);
		} else {
			updateSubtitles();

			setSubtitleLoader(false);
			setSubtitleSegments(res?.segments);
			setSubtitleRender(true);
		}
	}, [subtitleRenderId, subtitlesClient, updateSubtitles]);

	const getCutSegmentsVideo = useCallback(async () => {
		let res = await subtitlesClient.getSubtitles(subtitleRenderId);

		if (res?.currentStatus !== 5) {
			setTimeout(getCutSegmentsVideo, 10000);
		} else {
			let res = await projectClient.get(id);
			await updateProject(res);

			updateSubtitles();
			setSubtitleCutLoader(false);
			setSubtitleRender(false);
		}
	}, [id, projectClient, subtitleRenderId, subtitlesClient, updateProject, updateSubtitles]);

	const postSubtitles = useCallback(async () => {
		if (!subtitleRenderId && modifiedSegments.length === 0) return;
		setSubtitleCutLoader(true);

		const res = await subtitlesClient.cutSegments({renderId: subtitleRenderId, segments: modifiedSegments });

		if (res?.message.isSuccess) getCutSegmentsVideo();
		else {
			setSubtitleCutLoader(false);
			return;
		}
	}, [getCutSegmentsVideo, modifiedSegments, subtitleRenderId, subtitlesClient]);

	useEffect(() => {
		if (modifiedSegments.length > 0) {
			postSubtitles();
		}
	}, [modifiedSegments, postSubtitles]);

	useEffect(() => {
		if (subtitleLoader) {
			getSubtitles();
		}
	}, [getSubtitles, subtitleLoader]);

	return {
		subtitlesUrl,
		subtitleRender,
		subtitleCheck,
		subtitleSegments,
		subtitleCutLoader,
		subtitleLoader,
		addSubtitles,
		updateSubtitles,
		setModifiedSegments,
		setSubtitleCheck
	};
};

export default useSubtitleProject;
