export class UserChangeSecret {
	oldSecret = "";
	newSecret = "";

	constructor(oldSecret, newSecret) {
		this.oldSecret = oldSecret;
		this.newSecret = newSecret;
	}
}

export class UserProfile {
	phoneNumber = "";
	firstName = "";
	lastName = "";

	constructor(phoneNumber, firstName, lastName) {
		this.phoneNumber = phoneNumber;
		this.firstName = firstName;
		this.lastName = lastName;
	}
}