import { useTranslation } from 'react-i18next';

import { getPeriodText } from '../../helpers/timeHelper';

const StatusBadge = ({ project }) => {
	const { t } = useTranslation();

	if (project.status < 0) {
		return (
			<small
				className="badge rounded-pill bg-danger"
				data-status={project.status}
			>
				error
			</small>
		);
	}
	
	if (project.status === 0 || project.status === 2) {
		return (
			<small
				className="badge rounded-pill bg-info"
				data-status={project.status}
			>
				ready
			</small>
		);
	}

	if (project.status === 3) {
		if (project.positionInQueue > 0) {
			return (
				<span
					className="badge rounded-pill bg-warning"
					data-status={project.status}
				>
					{t("queue")}: {getPeriodText(project.lastActive)}
				</span>
			);
		}

		return (
			<span
				className="badge rounded-pill bg-warning"
				data-status={project.status}
			>
				{project.percentReady ? (project.percentReady * 100).toFixed(2) : 0}%
			</span>
		);
	}

	if (project.status === 4) {
		return (
			<span
				className="badge rounded-pill bg-success"
				data-status={project.status}
			>
				success
			</span>
		);
	}

	if (project.status === 5) {
		return (
			<span
				className="badge rounded-pill bg-secondary"
				data-status={project.status}
			>
				cancelling
			</span>
		);
	}

	if (project.status === 6) {
		return (
			<span
				className="badge rounded-pill bg-secondary"
				data-status={project.status}
			>
				cancelled
			</span>
		);
	}

	return (
		<span
			className="badge rounded-pill bg-secondary"
			data-status={project.status}
		>
			no files
		</span>
	);
};

export default StatusBadge;
