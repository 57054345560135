import { useState } from 'react';

import { Download } from 'react-bootstrap-icons';
import Card from '../../components/card';

import SharedProject from '../../widgets/sharedProject';

function SharedPage() {
	const [videoSource, setVideoSource] = useState("");
	// const [videoPreviewSource, setVideoPreviewSource] = useState("");
	const [project, setProject] = useState("");

	return (
		<Card>
			<div className="link text-black d-flex align-items-center justify-content-between mb-1">
				<h3>{project.name}</h3>
				<a className="btn btn-dark" href={videoSource}>
					<Download />
				</a>
			</div>
			<SharedProject
				project={project}
				videoSource={videoSource}
				setProject={setProject}
				setVideoSource={setVideoSource}
			/>
		</Card>
	);
}

export default SharedPage;
