import './index.scss';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/modal';
import InputValidate from '../../components/input/InputValidate';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';

import ProfileClient from '../../api/profileClient';

function ForgotPasswordModal ({ active, setModalActive }) {
	const { register, handleSubmit, formState: { errors } } = useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [submitMessage, setSubmitMessage] = useState({
		type: false,
		message: "",
	});

	const { t } = useTranslation();

	const profileClient = new ProfileClient();

	const onSubmit = async (data) => {
		setIsLoading(true);

		try {
			const res = await profileClient.resetPass(data.email);
			
			if (res.successful) {
				setSubmitMessage({ type: true, message: "sendResetMail"});
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			
			setIsLoading(false);
			setSubmitMessage({ type: false, message: "passNotChanged"});
		}
	};

	const closeModal = () => {
		setModalActive(false);
		setSubmitMessage({ type: false, message: "" });
	};

	return (
		<Modal activeModal={active} closeModal={closeModal}>
			<h2>{t("passwordChange")}</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="col-12 mb-4 d-flex flex-column gap-1">
					<InputValidate
						label={t("emailRequired")}
						name="email"
						register={register}
						errors={errors}
						validation={{
							required: t("emailRequired"),
							pattern: { value: /\S+@\S+\.\S+/, message: t("wrongEmailFormat") }
						}}
					/>
					{
						submitMessage.message &&
							<span className={`text text-${submitMessage.type ? 'success' : 'danger'}`}>
								{t(submitMessage.message)}
							</span>
					}
				</div>
				<ButtonSubmit
					text={ isLoading ? t("loading") : t("sendCode")}
					disabled={isLoading}
				/>
			</form>
		</Modal>
	);
}

export default ForgotPasswordModal;