import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/modal';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';
import InputValidate from '../../components/input/InputValidate';

import { ProjectContext } from '../../context/projectContext';

import BotNotify from '../../api/botNotify';

function FeedbackForm({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();

	const [ botNotifyMessage, setBotNotifyMessage ] = useState(null);

	const botNotify = new BotNotify();

	const context = useContext(ProjectContext);
	const { id, project } = context?.render;
	
	const userState = useSelector((state) => state.user.profile);

  const onSubmit = async (data) => {
		const payload = {
			userId: userState.userId,
			email: userState.email,
			projectId: id,
			errorMessage: project?.lastError || null,
			message: data.message,
		};

		const response = await botNotify.postError(payload);

		setBotNotifyMessage(response);
  };

	const closeModalHandler = () => {
		closeModal();
		setBotNotifyMessage(null);
	};

  return (
    <Modal activeModal={isOpen} closeModal={closeModalHandler}>
			{
				botNotifyMessage ? (
					<h4 className={`alert text-center ${botNotifyMessage?.error ? 'text-danger' : 'text-success'}`}>
						{botNotifyMessage?.error ? t('botMessageError') : t('botMessageSuccess')}
					</h4>
				) : (
					<>
						<h2 className='m-0'>{t('bugReport')}</h2>
						<form className='d-flex row gap-5 justify-content-center' onSubmit={handleSubmit(onSubmit)}>
							<div className='col-12'>
								<InputValidate
									label={t('feedbackMessage')}
									name="message"
									type="text"
									register={register}
									errors={errors}
									validation={{
										required: t("botMessageRequired"),
										minLength: { value: 10, message: t("botMessageMinLength") },
									}}
								/>
							</div>
							<ButtonSubmit text={t('send')} />
						</form>
					</>
				)
			}
    </Modal>
  );
}

export default FeedbackForm;
