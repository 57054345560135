import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

function ProjectPlayerTab({ carouselRef, currentVideoType}) {
	const { t } = useTranslation();
	
	return (
		<div className="d-flex gap-2 mt-3 mb-1">
			<Button
				className={`${currentVideoType === "original" ? "active" : ""}`}
				style={{ width: '100px' }}
				variant='primary'
				size='sm'
				onClick={() => carouselRef.current.moveTo(0)}
			>
				{t("original")}
			</Button>
			<Button
				className={currentVideoType === "preview" ? "active" : ""}
				style={{ width: '100px' }}
				variant='primary'
				size='sm'
				onClick={() => carouselRef.current.moveTo(1)}
			>
				{t("preview")}
			</Button>
		</div>
	);
}

export default ProjectPlayerTab;