import { useTranslation } from 'react-i18next';

function NewProjectSteps({ isUploading, videoClicked }) {
	const { t } = useTranslation();

	return (
		!isUploading && (
			!videoClicked ?
				<div className="link text-secondary d-flex justify-content-end flex-wrap">
					<h5 className="project_faq project_faq_first">
						{t("nextStep")} {t("chooseVideofile")}
					</h5>
				</div>
			:
				<div className="link text-secondary d-flex justify-content-end flex-wrap">
					{
						videoClicked &&
							<h5 className="project_faq project_faq_second">
								{t("nextStep")} {videoClicked}
							</h5>
					}
				</div>
		)
	);
}

export default NewProjectSteps;
