import { Config } from '../config';

class BotNotify {
	/**
	 * Отправка сообщения об ошибки tg-боту
	 * @param {Object} data - данные для отправки
	 * @param {string} data.userId
	 * @param {string} data.email
	 * @param {string | null} data.projectId
	 * @param {string | null} data.errorMessage
	 * @param {string | null} data.message
	 * @returns {{ message: string }} message
	 */

	async postError(data) {
		try {
      const response = await fetch(`${Config.bot}/bot/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      return result;
    } catch (error) {
      console.error(error);
      
      throw error;
    }
	}
}

export default BotNotify;