import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '../../../components/card';
import Modal from '../../../components/modal';
import ResetPasswordForm from '../../../widgets/resetPasswordForm';

function ResetPasswordPage () {
	const [modalActive, setModalActive] = useState(false);
	const [modalMessage, setModalMessage] = useState({
		type: false,
		message: ""
	});

	const { t } = useTranslation();

	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);

	let token = queryParams.get('token');
	let userId = queryParams.get('userId');

	const handleCloseModal = () => {
		setModalActive(false);
		setModalMessage(prev => ({ ...prev, message: "" }));

		if (modalMessage.type) {
			navigate('/login');
		}
	};

	useEffect(() => {
		if (!token || !userId) {
			navigate('/login');
		}
	}, [navigate, token, userId]);
	
	return (
		<>
			<Card>
				<div className="modal_wrapper">
					<h2>{t("changePassword")}</h2>
					<ResetPasswordForm
						token={token}
						userId={userId}
						setModalActive={setModalActive}
						setModalMessage={setModalMessage}
					/>
				</div>
			</Card>
			<Modal
				activeModal={modalActive}
				closeModal={handleCloseModal}
			>
				<h3 className="text-center">
					{t(modalMessage.message)}
				</h3>
			</Modal>
		</>
	);
}

export default ResetPasswordPage;