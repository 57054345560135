import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ProfileClient from '../../api/profileClient';

const profileClient = new ProfileClient();

const initialState = {
	profile: null,
	errorMessages: null,
	isAccountSettingsSuccess: false
};

export const getUser = createAsyncThunk('user/getUser',
	async () => {
		const result = await profileClient.get();

		return result;
});

export const changeUserInfo = createAsyncThunk('user/changeUserInfo',
	async (data, { dispatch }) => {
		const result = await profileClient.update(data.userId, data.userData);

		if (result.successful) {
			setTimeout(() => {
				dispatch(userActions.changeAccountSettingsSuccess());
			}, 5000);
		}

		return result;
});

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setProfile: (state, action) => {
			state.profile = action.payload;
		},
		resetUser: (state) => {
			state.profile = null;
		},
		resetErrorMessages: (state) => {
			state.errorMessages = null;
		},
		changeAccountSettingsSuccess: (state) => {
			state.isAccountSettingsSuccess = false;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getUser.fulfilled, (state, action) => {
			state.profile = action.payload;
		});
		builder.addCase(changeUserInfo.fulfilled, (state, action) => {
			if (action.payload.errors) {
				state.errorMessages = action.payload.errors;
				state.isAccountSettingsSuccess = false;
			} else {
				state.profile = { ...state.profile, ...action.meta.arg.userData };
				state.isAccountSettingsSuccess = true;
			}
		});
	}
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
