import './index.scss';

import { useState, useEffect, useCallback } from "react";
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

const addTextTrack = (videoNode, trackLang, subtitlesUrl) => {
	if (!videoNode || !subtitlesUrl) return;

  const existingTracks = videoNode.querySelectorAll(`track[srclang="${trackLang}"]`);

  existingTracks.forEach(track => {
    videoNode.removeChild(track);
  });

  const track = document.createElement("track");

  track.kind = "subtitles";
  track.srclang = trackLang;
  track.src = subtitlesUrl;
  track.default = true;

  videoNode.appendChild(track);

  const textTracks = videoNode.textTracks;

  for (let i = 0; i < textTracks.length; i++) {
    textTracks[i].mode = textTracks[i].language === trackLang ? "showing" : "hidden";
  }
};

const VideoPlayer = ({
	videoId,
	videoRef,
	src,
	subtitlesUrl,
	updateSubtitles,
	isVertical = false,
	onProgress,
	onDuration
}) => {
	const [trackLang, setTrackLang] = useState("RU");

	const { t } = useTranslation();

	const onReadyVideoPlayer = useCallback(() => {
		const videoNode = videoRef.current?.getInternalPlayer();

		if (videoNode) {
			addTextTrack(videoNode, trackLang, subtitlesUrl);
		}
	}, [subtitlesUrl, trackLang, videoRef]);

	useEffect(() => {
		if(updateSubtitles) {
			updateSubtitles();
		}
	}, [updateSubtitles]);

  useEffect(() => {
    if (subtitlesUrl) {
			onReadyVideoPlayer();
    }
  }, [subtitlesUrl, onReadyVideoPlayer]);

	return (
		<div className={`video-container ${isVertical ? 'video_vertical' : ''}`}>
			<div className="video-overlay" />
			<ReactPlayer
				id={videoId}
				ref={videoRef}
				url={src}
				className="video-player"
				width='100%'
				height='100%'
				controls={true}
				playsinline={true}
				onReady={onReadyVideoPlayer}
				onProgress={onProgress}
				onDuration={onDuration}
				config={{
					file: {
						forceVideo: true,
						attributes: {
							crossOrigin: 'anonymous',
							preload: 'auto'
						},
						tracks: []
					}
				}}
			/>
			{
				videoId === "previewVideoID" && (
					<p className="video-text">
						{t("preview")}
					</p>
				)
			}
		</div>
	);
}

export default VideoPlayer;
