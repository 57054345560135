import './slider.scss';

import RangeSlider from 'react-range-slider-input';

function Slider({ value, onChange, min, max, step, format, isRange = false }) {
	const formatValue = (value) => format ? (format(value)) : value;

	return (
		<>
			<div className="d-flex justify-content-between">
				<span className="text-secondary">
					{formatValue(isRange ? value[0] : value)}
				</span>
				{
					isRange ?
						<span className="text-secondary">
							{formatValue(value[1])}
						</span>
					: 
						<span className="text-secondary">
							{formatValue(max)}
						</span>
				}
			</div>
			{
				isRange &&
					<RangeSlider
						min={min}
						max={max}
						step={step}
						value={value}
						onInput={onChange}
					/>
			}
			{
				!isRange &&
					<RangeSlider
						className="single-thumb"
						min={min}
						max={max}
						step={step}
						value={[min, value]}
						thumbsDisabled={[true, false]}
						onInput={(v) => onChange(v[1])}
					/>
			}
		</>
	);
}

export default Slider;