import './index.scss';

import { X } from 'react-bootstrap-icons';

function Modal({ activeModal, closeModal, children }) {
	return (
		<div
			className={activeModal ? "modal active" : "modal"}
			onClick={closeModal}
		>
			<div
				className={activeModal ? "modal_content active" : "modal_content"}
				onClick={e => e.stopPropagation()}
			>
				<button
					className='position-absolute top-0 end-0 modal_close'
					onClick={closeModal}
				>
					<X width={32} height={32} />
				</button>
				<div className="modal_wrapper">
					{children}					
				</div>
			</div>
		</div>
	)
}

export default Modal;