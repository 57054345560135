import AuthHelper from '../helpers/authHelper';

import { Config } from '../config';

class ProjectClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Получить список проектов
	 * 
	 * @return {[Object]} Лист проектов
	 */
	async getAll() {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/project`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Получить проект по id
	 * 
	 * @param {string} projectID id проекта
	 * @return {Object} проект
	 */
	async get(projectID) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/project/${projectID}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Получить проект, доступный по ссылке
	 * 
	 * @param {string} share короткая ссылка
	 * @return {Object} проект
	 */
	async getShare(share) {
		try {
			const res = await fetch(`${Config.host}/project/share/${share}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			});
	
			if (res.status === 404) return null;
			if (!res.ok) throw new Error(res.statusText);

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Удалить проект по id
	 * 
	 * @param {string} projectID id проекта
	 * @return {null}
	 */
	async remove(project) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/project/${project}`, {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);

			return null;
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default ProjectClient;