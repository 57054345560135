import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../components/card';
import ForgotPasswordModal from '../../../widgets/forgotPasswordModal';
import LoginForm from '../../../widgets/loginForm';

function LoginPage() {
	const [modalActive, setModalActive] = useState(false);

	const { t } = useTranslation();
	
	return (
		<>
			<Card>
				<div className="link text-secondary d-flex align-items-center justify-content-between pb-3">
					<h3>{t("login")}</h3>
				</div>
				<LoginForm handleForgotPasswordModal={setModalActive} />				
			</Card>
			<ForgotPasswordModal
				active={modalActive}
				setModalActive={setModalActive}
			/>
		</>
	);
}

export default LoginPage;