const projectVideosInventory = [
	{
		'camera': 0,
		'presets': [0],
		'name': "generalPlan"
	},
	{
		'camera': 1,
		'presets': [0,1],
		'name': "secondPlan"
	},
	{
		'camera': 2,
		'presets': [0,1,2],
		'name': "thirdPlan"
	}
];

export { projectVideosInventory };
