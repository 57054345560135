import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/logo.png';
import { Telegram } from 'react-bootstrap-icons';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavbarRoot from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LanguageSelector from '../language/languageSelector';

import { authActions } from '../../store/slices/authSlice';
import { userActions } from '../../store/slices/userSlice';

function Navbar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const userState = useSelector((state) => state.user.profile);

  const onLogout = () => {
    dispatch(authActions.removeJwt());
    dispatch(userActions.resetUser());
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  return (
    <NavbarRoot
      expand="lg"
      bg="blue"
      data-bs-theme="light"
      sticky="top"
    >
      <Container>
        <NavLink to="/" >
          <img
            height={100}
            src={logo}
            alt="logo"
          />
        </NavLink>
        <NavbarRoot.Toggle aria-controls="basic-navbar-nav" />
        <NavbarRoot.Offcanvas
          id={`offcanvasNavbar-expand`}
          data-bs-theme="light"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              {t("navigation")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3 justify-content-end">
              <NavLink className="nav-link" to="/faq">
                {t("instruction")}
              </NavLink>
            </Nav>
            <Nav className="pe-3 justify-content-end">
              <Link
                className="nav-link"
                to="https://t.me/videoneuron_chat"
                target="_blank"
              >
                <Telegram size={24} className="tg_button"/>
              </Link>
            </Nav>
            <Nav className="justify-content-end">
              <NavDropdown
                id="basic-nav-dropdown"
                title={t("language")}
              >
                <LanguageSelector/>
              </NavDropdown>
            </Nav>
            {
              userState &&
                <Nav className="justify-content-end pe-3">
                  <NavDropdown title={userState.email} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={() => navigate("/profile")}>
                      {t("accountSettings")}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onLogout}>
                      {t("exit")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
            }
          </Offcanvas.Body>
        </NavbarRoot.Offcanvas>
      </Container>
    </NavbarRoot>
  );
}

export default Navbar;