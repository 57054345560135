import AuthHelper from '../helpers/authHelper';

import { Config } from '../config';

class XmlClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Добавление xml файла в проект
	 * 
	 * @param {string} projectId - id проекта
	 * @returns {object} - объект с информацией о результате
	 */
	async addXml(projectId) {
		let xmlOptions = { projectId: projectId };
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.xml}/api/v1/fcpxml/${projectId}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify(xmlOptions)
			});
	
			if (!res.ok) throw new Error(res.statusText);

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default XmlClient;
