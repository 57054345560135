import { useState } from 'react';

import SubtitlesClient from '../api/subtitlesClient';
import XmlClient from '../api/xmlClient';

const useXmlProject = ({ id }) => {
	const [isLoadingFiles, setIsLoadingFiles] = useState(false);

	const subtitlesClient = new SubtitlesClient();
	const xmlClient = new XmlClient();

	const createXmlAndDownload = async () => {
		const xml = await xmlClient.addXml(id);

		if (xml) {
			setIsLoadingFiles(true);
			const projectFiles = await subtitlesClient.downloadFiles(id);

			const url = window.URL.createObjectURL(projectFiles);
			const downloadLink = document.createElement('a');

			downloadLink.href = url;
			downloadLink.setAttribute('download', `project_files_${id}.zip`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			setIsLoadingFiles(false);
		}
	};

	return {
		isLoadingFiles,
		createXmlAndDownload,
	};
};

export default useXmlProject;
