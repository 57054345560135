import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AuthClient from '../../api/authClient';

import AuthHelper from '../../helpers/authHelper';

const authClient = new AuthClient();
const authHelper = new AuthHelper();

const initialState = {
	jwt: authHelper.loadToken('token') ?? null,
	errorMessage: null,
};

export const authLogin = createAsyncThunk('auth/login',
	async (data) => {
		const result = await authClient.login(data);

		return result;
});

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addJwt: (state, action) => {
			state.jwt = action.payload;
			
			authHelper.setToken(action.payload);
		},
		removeJwt: (state) => {
			state.jwt = null;

			setTimeout(() => authHelper.clearToken(), 0);
		},
		resetErrorMessage: (state) => {
			state.errorMessage = null;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(authLogin.fulfilled, (state, action) => {
			if (!action.payload.token) {
				state.errorMessage = Object.keys(action.payload.errors)[0];

				return;
			}

			state.jwt = action.payload.token;
		});
	}
});

export const authActions = authSlice.actions;

export default authSlice.reducer;