import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import InputGroup from 'react-bootstrap/InputGroup';

import { Clipboard } from 'react-bootstrap-icons';

function SharePopover({ shareLink, shareInputRef, handleRemoveShare, copySharedVideo }) {
	return (
		<Popover id="popover-basic">
			<Popover.Header as="h3">{"Shared video"}</Popover.Header>
			<Popover.Body variant="dark">
				<InputGroup className="mb-3">
					<Form.Control
						defaultValue={shareLink}
						readOnly={true}
						ref={shareInputRef}
					/>
					<Button variant="dark" onClick={copySharedVideo}>
						<Clipboard />
					</Button>
				</InputGroup>
				<Stack direction="horizontal" gap={3}>
					<div className="ms-auto">
						<Button variant="danger" onClick={handleRemoveShare}>
							Remove share
						</Button>
					</div>
				</Stack>
			</Popover.Body>
		</Popover>
	);
}

export default SharePopover;