import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from '../../components/slider/Slider';
import { Checkbox } from '../../components/checkbox/Checkbox';
import TextEditor from '../../components/textEditor';

import { ProjectContext } from '../../context/projectContext';

import { secToTimeSpan } from '../../helpers/timeHelper';

function ProjectControls() {
	const { t } = useTranslation();

	const context = useContext(ProjectContext);

	const { project, duration, offset, setOffset, switchPlanTimeCheck  } = context?.render;

	const {
		minSwitchTime,
		maxSwitchTime,
		// maxSpeedUpSilence,
		// speedUpSilenceCheck,
		speedUpAllCheck,
		isVerticalCheck,
		// speedUpSilence,
		speedUpAll,
		switchTime,
		// setSpeedUpSilenceCheck,
		setSpeedUpAllCheck,
		setIsVerticalCheck,
		// setSpeedUpSilence,
		setSpeedUpAll,
		setSwitchTime
	} = context?.controls;

	const {
		subtitleRender,
		subtitleCheck,
		setSubtitleCheck,
		subtitleSegments,
		setModifiedSegments,
		subtitleCutLoader
	} = context?.subtitle;

	return (
		project?.status !== 3 && (
			<>
				<div className="col-12 mt-5">
					<label className="form-label text-secondary">
						{t("cut")}
					</label>
					<Slider
						max={duration}
						min={0}
						step={0.01}
						value={offset}
						onChange={setOffset}
						format={secToTimeSpan}
						isRange={true}
					/>
				</div>
				{
					(project?.preset === "interview" || project?.preset === "speech") && (
						<div className="col-12">
							<div className="form-label text-secondary">
								{t("planTimer")}
							</div>
							<div className="mb-1">
								{
									switchPlanTimeCheck && (
										<Slider
											min={minSwitchTime}
											max={maxSwitchTime}
											step={1}
											value={switchTime}
											onChange={setSwitchTime}
											format={(v) => `${v} sec`}
										/>
									)
								}
							</div>
						</div>
					)
				}

				{/* {
					(project?.preset === "speech" || project?.preset === "single") && (
						<div className="col-12">
							<Checkbox
								text={t("speedUpSilence")}
								id="speed-up-silence"
								value={speedUpSilenceCheck}
								setValue={setSpeedUpSilenceCheck}
							/>
							{
								speedUpSilenceCheck && (
									<Slider
											min={2}
											max={maxSpeedUpSilence}
											step={1}
											value={speedUpSilence}
											onChange={setSpeedUpSilence}
											format={(v) => `${v}x`}
									/>
								)
							}
						</div>
					)
				} */}

				<div className="col-12">
					<Checkbox
						text={t("speedUpVideo")}
						id="speed-up-all"
						value={speedUpAllCheck}
						setValue={setSpeedUpAllCheck}
					/>
					{
						speedUpAllCheck && (
							<Slider
								min={1}
								max={100}
								step={1}
								value={speedUpAll}
								onChange={setSpeedUpAll}
								format={(v) => `${v}%`}
							/>
						)
					}
				</div>

				<div className="col-12">
					<Checkbox
						text={t("verticalVideo")}
						id="vertical"
						value={isVerticalCheck}
						setValue={setIsVerticalCheck}
					/>
				</div>

				{
					subtitleRender && (
						<div className="col-12">
							<Checkbox
									text={t("subtitles")}
									id="subtitles"
									value={subtitleCheck}
									setValue={setSubtitleCheck}
							/>
							{
								subtitleCheck && 
									<TextEditor
										segments={subtitleSegments}
										setModifiedSegments={setModifiedSegments}
										loader={subtitleCutLoader}
									/>
							}
						</div>
					)
				}
			</>
		)
	);
}

export default ProjectControls;