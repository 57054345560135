import './index.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PencilSquare, CheckSquare } from 'react-bootstrap-icons';

function InputName({ setProjectName, projectName, placeholder, disabled }) {
	const [isEditing, setIsEditing] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const { t } = useTranslation();

	const validateForm = () => {
		if ((projectName.length > 0 && projectName.length < 2) || projectName.length > 30) {
			setErrorMessage(t("projectNameError"));

			return false;
		} else {
			setErrorMessage('');

			return true;
		}
	};
	
	const handleChange = (event) => {
		const value = event.target.value;
		
		setProjectName(value);
	};
	
	const handleToggle = () => {
		if (disabled) return;
		if (isEditing) {
			if (!validateForm()) return; 
		}

		setIsEditing(!isEditing);
	};

	return (
		<>
			<div className="d-flex flex-row align-items-center gap-2">
				<input
					className="input-project-name"
					id="projectName"
					name="projectName"
					type="text"
					value={projectName}
					disabled={!isEditing}
					onChange={handleChange}
					placeholder={placeholder}
					min={2}
					max={30}
				/>
				{
					isEditing ?
						<CheckSquare width={22} height={22} type="button" onClick={handleToggle} />
					:
						<PencilSquare width={22} height={22} type="button" onClick={handleToggle} />
				}
			</div>
			{errorMessage && <span className="text-danger d-inline-flex mt-1">{errorMessage}</span>}
		</>
	);
}

export default InputName;
