import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputValidate from '../../components/input/InputValidate';
import { FormErrors } from '../../components/input/FormErrors';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';

import { UserChangeSecret } from '../../contracts/userProfile';
import ProfileClient from '../../api/profileClient';

function ChangePasswordProfileForm() {
	const {
		register: registerForChangePassword,
		handleSubmit: handleSubmitForChangePassword,
		formState: { errors: changePasswordFormErrors },
		reset
	} = useForm();

	const [changePasswordErrors, setChangePasswordErrors] = useState({});
	const [isChangePasswordSuccess, setChangePasswordSuccess] = useState(false);
	
	const { t } = useTranslation();
	const profileClient = new ProfileClient();

	const onSubmitChangePassword = async (data) => {
		const userChangeSecret = new UserChangeSecret(data.oldPassword, data.newPassword);
		
		const result = await profileClient.changeSecretAsync(userChangeSecret);

		if (result.successful) {
			setChangePasswordErrors({});
			reset();
		} else {
			setChangePasswordErrors(result.errors);
		}

		setChangePasswordSuccess(result.successful);
	};

	const handleChangePassword = () => {
		setChangePasswordSuccess(false);
	};
	
	return (
		<form
			className="row g-3"
			onSubmit={handleSubmitForChangePassword(onSubmitChangePassword)}
			onChange={handleChangePassword}
		>
			<div className="col-6">
				<InputValidate
					label={t("oldPass")}
					type="password"
					name="oldPassword"
					required
					register={registerForChangePassword}
					errors={changePasswordFormErrors}
					validation={{ required: t("oldPassRequired") }}
				/>
			</div>
			<div className="col-6">
				<InputValidate
					label={t("newPass")}
					type="password"
					name="newPassword"
					errors={changePasswordFormErrors}
					register={registerForChangePassword}
					required
					validation={{
						required: t("newPassRequired"),
						minLength: { value: 6, message: t("minPassLength") }
					}}
				/>
			</div>
			<FormErrors
				errors={changePasswordErrors}
				formErrors={changePasswordFormErrors}
				successful={isChangePasswordSuccess}
				defaultMessage={t("passChanged")}
			/>
			<div className="d-flex justify-content-center gap-2">
				<ButtonSubmit text={t("changePassword")} />
			</div>
		</form>
	);
}

export default ChangePasswordProfileForm;