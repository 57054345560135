import AuthHelper from '../helpers/authHelper';

import { Config } from '../config';

class RenderClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Получение детализации рендеринга
	 * @param {string} projectID - id проекта
	 * @returns {object} - детализация
	 */
	async getStoryboard(projectID) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/render/${projectID}/storyboard`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);
			
			const text = await res.text();
	
			if (!text) return null;
	
			const json = JSON.parse(text);
	
			return json;
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Отправка видео в очередь на рендеринг
	 * 
	 * @param {string} projectID - id проекта
	 * @param {Settings} settings - настройки рендеринга
	 * @returns {object} - результат рендеринга
	 */
	async render(projectID, settings) {
		const token = this.authHelper.getToken();
		settings.enhanced = true;
		
		try {
			const res = await fetch(`${Config.host}/render/${projectID}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify(settings)
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}


	/**
	 * Отправка видео превью на рендеринг
	 * 
	 * @param {string} projectID - id проекта
	 * @returns {object} - результат рендеринга
	 */
	async renderPreview(projectID) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/render/${projectID}/preview`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.log(error);

			throw error;
		}
	}

	/**
	 * Отмена рендеринга
	 * 
	 * @param {string} projectID - id проекта
	 * @returns {null}
	 */
	async cancel(projectID) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/render/${projectID}`, {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return null;
		} catch (error) {
			console.log(error);

			throw error;
		}
	}

	/**
	 * Проверка существования файла
	 * 
	 * @param {string} path - ссылка на файл
	 * @returns {null|object} - объект с ссылкой на видео 
	 */
	async isFileExists(path) {
		const token = this.authHelper.getToken();

		if (path) {
			try {
				const res = await fetch(path,	{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then((res) => {
						return res;
					})
					.catch((err) => {
						return null;
					})
					.then((data) => data)
					.catch((err) => {
						return null;
					});

				if (!res || res.status !== 200) {
					return null;
				}

				return res;
			} catch (err) {
				console.log(err);

				return null;
			}
		} else {
			return null;
		}
	}
}

export default RenderClient;
