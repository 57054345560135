import 'react-confirm-alert/src/react-confirm-alert.css';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'react-bootstrap';

import { ProjectContext } from '../../context/projectContext';

function ProjectRenderError() {
	const { t }	= useTranslation();

	const context = useContext(ProjectContext);

	const { renderError, setShowError } = context?.render;

	return (
		renderError &&
			<Alert
				key={"danger"}
				variant={"outline-danger"}
				onClose={() => setShowError(false)}
				dismissible
			>
				<h4 className="alert-heading">{t("error")}</h4>
				{renderError}
			</Alert>
	);
}

export default ProjectRenderError;
