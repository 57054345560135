import intro from '../../assets/intro.png';
import share from '../../assets/share.jpg';
import single from '../../assets/single.png';
import speech from '../../assets/speech.png';
import interview from '../../assets/interview.png';
import settings from '../../assets/settings.png';

const carousel_data = [
	{
		id: 0,
		src: intro,
		alt: 'start',
		heading: 'start',
		paragraph: 'prepareFormat',
	},
	{
		id: 2,
		src: interview,
		alt: 'threeCameras',
		heading: 'threeCameras',
		paragraph: 'threeCamerasDecs',
	},
	{
		id: 3,
		src: speech,
		alt: 'twoCameras',
		heading: 'twoCameras',
		paragraph: 'twoCamerasDesc',
	},
	{
		id: 4,
		src: single,
		alt: 'oneCamera',
		heading: 'oneCamera',
		paragraph: 'oneCameraDesc',
	},
	{
		id: 5,
		src: settings,
		alt: 'changeSettings',
		heading: 'changeSettings',
		paragraph: 'changeSettingsDesc',
	},
	{
		id: 6,
		src: share,
		alt: 'shareCarousel',
		heading: 'shareCarousel',
		paragraph: 'shareCarouselDesc',
	},
];

export default carousel_data;
