import { useState } from "react";
import { useForm } from "react-hook-form";

import InputValidate from "../../components/input/InputValidate";
import { ButtonSubmit } from "../../components/button/ButtonSubmit";

import { Eye, EyeSlash } from 'react-bootstrap-icons';

import ProfileClient from '../../api/profileClient';
import { t } from 'i18next';

function ResetPasswordForm({ token, userId, setModalActive, setModalMessage }) {
	const [showPassword, setShowPassword] = useState({
		password: false,
		repeatPassword: false
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();
	const password = watch("password");

	const profileClient = new ProfileClient();

	const togglePasswordVisibility = (field) => {
		setShowPassword(prevState => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	const onSubmit = async (data) => {
		const res = await profileClient.setNewPassword({ userId, ...data, token });

		if (res === null) {
			setModalMessage({
				type: true,
				message: "passChanged",
			});
			setModalActive(true);
		} else {
			setModalMessage({
				type: false,
				message: "passNotChanged",
			});
			setModalActive(true);

			console.log(res?.errors);
		}
	};

	return (
		<form
			className="col-12 mb-4 d-flex flex-column align-items-center gap-3"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="col-12 pass_input">
				<InputValidate
					label={t("newPass")}
					name="password"
					type={showPassword.password ? "text" : "password"}
					register={register}
					errors={errors}
					validation={{ required: `${t("newPassRequired")}` }}
				/>
				{
					showPassword.password ?
						<EyeSlash className="eye_button" onClick={() => togglePasswordVisibility("password")} />
					:
						<Eye className="eye_button" onClick={() => togglePasswordVisibility("password")} />
				}
			</div>
			<div className="col-12 pass_input">
				<InputValidate
					label={t("comfirmPass")}
					name="repeatPassword"
					type={showPassword.repeatPassword ? "text" : "password"}
					register={register}
					errors={errors}
					validation={{
						required: `${t("newPassRequired")}`,
						validate: (value) => value === password || `${t("passNotMatch")}`
					}}
				/>
				{
					showPassword.repeatPassword ?
						<EyeSlash className="eye_button" onClick={() => togglePasswordVisibility("repeatPassword")} />
					:
						<Eye className="eye_button" onClick={() => togglePasswordVisibility("repeatPassword")} />
				}
			</div>
				<ButtonSubmit text={t("comfirm")} />
		</form>
	);
}

export default ResetPasswordForm;