import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import VideoPlayer from '../../components/videoPlayer';

import { Config } from '../../config';
import ProjectClient from '../../api/projectClient';
import SubtitlesClient from '../../api/subtitlesClient';
// import RenderClient from '../../api/renderClient';
// import AuthHelper from '../../helpers/authHelper';

function SharedProject({ project, videoSource, setProject, setVideoSource }) {
	const [subtitlesUrl, setSubtitlesUrl] = useState(null);

	const navigate = useNavigate();
	const { share } = useParams("share");

	const video = useRef(null);
	
	const projectClient = useMemo(() => new ProjectClient(), []);
	// const renderClient = new RenderClient();
	const subtitlesClient = useMemo(() => new SubtitlesClient(), []);

	const updateVideoSource = useCallback((share, renderKey, videoType) => {
		let videoUrl = videoType === 'original'
			? `${Config.host}/storage/share/${share}/stream`
			: `${Config.host}/storage/share/${share}/previewstream`;

		if (renderKey != null) {
			const url = new URL(videoUrl);

			url.searchParams.set("key", renderKey);
			videoUrl = url.toString();
		}

		setVideoSource(videoUrl);
	}, [setVideoSource]);

	const getShareProject = useCallback(async () => {
		const shareModel = await projectClient.getShare(share);

		if (!shareModel) {
			navigate(`/`);
			
			return;
		}

		setProject(shareModel);
		updateVideoSource(share, shareModel.renderKey, shareModel.videoType);
	}, [navigate, projectClient, setProject, share, updateVideoSource]);

	// const getPreviewVideoSource = async (id, proj) => {
	// 	const authHelper = new AuthHelper();
	// 	const token = authHelper.getToken();

	// 	let result = '';
	// 	let videoPreviewUrl = `${Config.host}/storage/${id}/previewstream/`;

	// 	if (token != null) {
	// 		const previewUrl = new URL(videoPreviewUrl);
		
	// 		previewUrl.searchParams.set("token", token);
	// 		previewUrl.searchParams.set("key", proj.renderKey);
	// 		videoPreviewUrl = previewUrl.toString();
	// 	}

	// 	await renderClient.isFileExists(videoPreviewUrl)
	// 		.then(rc => {
	// 			setVideoPreviewSource(rc.url);
	// 			result = rc.url;
	// 		})
	// 		.catch(er => {
	// 			setVideoPreviewSource(null);
	// 			result = null;
	// 		});

	// 	return result;
	// };

	const getSubtitles = useCallback(async (id) => {
		try {
			const webVttText = await subtitlesClient.getSubtitlesWebVtt(id);

			if (webVttText) {
				const blob = new Blob([webVttText], { type: 'text/vtt' });
				const url = URL.createObjectURL(blob);
                
				setSubtitlesUrl(url);
			} else {
				console.log("Субтитры не найдены");
			}
		} catch (error) {
			console.log("Ошибка при получении субтитров:", error);
		}
	}, [subtitlesClient]);

	useEffect(() => {
		getShareProject();
	}, [getShareProject]);

	useEffect(() => {
		if (project) getSubtitles(project.id);
	}, [getSubtitles, project]);

	return (
		<div className="d-flex flex-column">
			{
				project &&
					<VideoPlayer
						videoId="sharedVideoID"
						src={videoSource}
						videoRef={video}
						subtitlesUrl={subtitlesUrl}
					/>
			}
		</div>
	);
}

export default SharedProject;