import classNames from 'classnames';

function InputValidate({ name, label, register, type, errors, validation }) {
	const inputClassName = classNames({
		"form-control": true,
		"is-invalid": errors && errors[name]
	});

	return (
		<div className="form-floating">
			{
				register &&
					<input
						id={"id-" + name}
						className={inputClassName}
						type={type}
						placeholder={label}
						aria-invalid={errors && errors[name] ? "true" : "false"}
						{...register(name, validation)}
					/>
			}
			{
				!register &&
					<input
						id={"id-" + name}
						className={inputClassName}
						type={type}
					/>
			}
			{
				(errors == null || !errors.hasOwnProperty(name)) &&
					<label htmlFor={"id-" + name}>
						{label}
					</label>
			}
			{
				errors && errors[name] &&
					<label
						className="text-danger invalid-feedback"
						htmlFor={"id-" + name}
					>
						{errors[name]?.message}
					</label>
			}
		</div>
	);
}

export default InputValidate;