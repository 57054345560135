import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Bug, Download } from 'react-bootstrap-icons';

import { Button } from 'react-bootstrap';
import ShareVideo from '../../widgets/shareVideo';

import { ProjectContext } from '../../context/projectContext';

function ProjectBar({ openModal }) {
	const { t } = useTranslation();

	const context = useContext(ProjectContext);

	const { project, videoSource, videoPreviewSource, renderError } = context?.render;
	const { currentVideoType } = context?.video;

	return (
		<>
			{
				project?.status === 4 && (
					<>
						<ShareVideo
							className="ms-1"
							projectId={project.id}
							videoType={currentVideoType}
						/>
						<a
							title={t("downloadMainVideo")} 
							style={{color:'blue'}}
							className="ms-1 btn btn-dark" 
							href={videoSource}
						>
							<Download />
						</a>
						{
							videoPreviewSource?.length > 0 ? (
								<a
									title={t("downloadPreview")}
									style={{ color: 'lightgreen' }}
									className="ms-1 btn btn-dark"
									href={videoPreviewSource}
								>
									<Download />
								</a>)
							: null
						}
					</>
				)
			}
			{
				(project?.status === 4 || project?.lastError || renderError) && (
					<Button
						className='btn btn-dark ms-1'
						title={t("bugReport")}
						onClick={openModal}
					>
						<Bug color='#953553' />
					</Button>
				)
			}
		</>
	);
}

export default ProjectBar;
