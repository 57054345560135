import { useTranslation } from 'react-i18next';

const languages = [
	{code: "ru", lang: "Russian"},
	{code: "en", lang: "English"}
];

const LanguageSelector = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (lng) => i18n.changeLanguage(lng);

	return (
		<div className="d-flex flex-column gap-1">
			{
				languages.map((lng) => {
					return (
						<button
							key={lng.code}
							className="btn_lng_select"
							onClick={() => changeLanguage(lng.code)}
						>
							{lng.lang}
						</button>
					);
				})
			}
		</div>
	);
};

export default LanguageSelector;