import  BootstrapCard from "react-bootstrap/Card";

export default function Card({ children }) {
	return (
		<BootstrapCard
			className="card_animate bg-opacity-10 p-2 position-relative"
			variant={"light"}
			border={"primary"}
			bg={"info"}
		>
			<BootstrapCard.Body>
				{children}
			</BootstrapCard.Body>
		</BootstrapCard>
	);
}