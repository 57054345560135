import 'react-confirm-alert/src/react-confirm-alert.css';

import { useContext } from 'react';

import { Alert } from 'react-bootstrap';

import { ProjectContext } from '../../context/projectContext';

function ProjectFormError() {
	const context = useContext(ProjectContext);

	const { project, setShowError } = context?.render;

	return (
		project?.lastError && (
			<Alert
				key={"danger"}
				variant={"outline-danger"}
				onClose={() => setShowError(false)}
				dismissible
			>
				<h4 className="alert-heading">
					{project.renderState || "Render error"}
				</h4>
				<p>{project.lastError}</p>
			</Alert>
		)
	);
}

export default ProjectFormError;
