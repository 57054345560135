const YaShare = ({ shareLink, isShared }) => {
	if (window.Ya) {
		const yaShare = window.Ya.share2('ya', {
			theme: {
				services: 'telegram,whatsapp,vkontakte,messenger',
				popupDirection: "auto",
				copy: "last",
				limit: 2,
				size: "m",
				curtain: true,
				bare: false,
				colorScheme: "blackwhite"
			},
			content: { url: shareLink, title: "Share video" }
		});

		if (!isShared && yaShare) {
			yaShare.destroy();
		}
	}

	return (
		<div id="ya" className="me-1" />
	);
};

export default YaShare;