import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputName from '../../../components/inputName';
import ProgressBar from '../../../components/progressBar';
import { ButtonSubmit } from '../../../components/button/ButtonSubmit';

import NewProjectUpload from '../newProjectUpload';

import { Settings } from '../../../contracts/project';

import useUploadVideo from '../../../hooks/useUploadVideo';
import UploadClient from '../../../api/uploadClient';

function NewProjectForm({ isUploading, setUploading, handleVideoClicked }) {
	const { t } = useTranslation();

	const { register, handleSubmit, formState: { errors } } = useForm();
	const navigate = useNavigate();

	const [files, setFiles] = useState(new Map());
	const [preset, setPreset] = useState(0);
	const [errorMessage, setErrorMessage] = useState({});
	const [uploadError, setUploadError] = useState('');
	const [uploadMessage, setUploadMessage] = useState('');

	const [track, setTrack] = useState(0);
	const [progress, setProgress] = useState(0);
	
	const presets = ['single', 'speech', 'interview'];
	const [projectName, setProjectName] = useState('');

	const {
		projectVideos,
		handleOnChange,
		handleRemoveFile,
		validateFiles,
		handleAddVideo,
		handleRemoveVideo
	} = useUploadVideo({
		files,
		setFiles,
		preset,
		setPreset,
		setErrorMessage,
		uploadError,
		setUploadError
	});

	const upload = async () => {
		const uploader = new UploadClient();
		const settings = new Settings();

		const videoFiles = [];

		uploader.OnProgress = setProgress;
		uploader.OnLoad = setUploading;
		uploader.OnComplete = onFinish;
		uploader.uploadError = setUploadError;
		uploader.uploadMessage = setUploadMessage;

		uploader.project.name = projectName;
		uploader.project.preset = presets[preset];
		settings.audioSourceIndex = Number(track);
		uploader.project.settings = settings;
		uploader.project.fileNames = {};
		
		files.forEach((_video, key) => {
			videoFiles.push(..._video);

			const mapped = [..._video].map(f => f.name);
			uploader.project.fileNames[key] = mapped;
		});

		uploader.createUpload(videoFiles);
	};

	const onSubmit = (data) => {
		if (uploadError) setUploadError('');

		if (validateFiles()) upload(data);
	};

	const onFinish = (uploadedProject) => {
		if (!uploadedProject) return;

		navigate(`/project/${uploadedProject.id}`);
	};

	return (
		<form
			className="row g-3"
			onSubmit={handleSubmit(onSubmit)}
			disabled={isUploading}
		>
			<InputName
				projectName={projectName}
				setProjectName={setProjectName}
				placeholder={t("newProject")}
				disabled={isUploading}
			/>
			<>
				<NewProjectUpload
					projectVideos={projectVideos}
					preset={preset}
					track={track}
					errors={errors}
					uploadError={uploadError}
					errorMessage={errorMessage}
					isUploading={isUploading}
					handleVideoClicked={handleVideoClicked}
					register={register}
					setTrack={setTrack}
					setErrorMessage={setErrorMessage}
					handleOnChange={handleOnChange}
					handleRemoveFile={handleRemoveFile}
					handleRemoveVideo={handleRemoveVideo}
					handleAddVideo={handleAddVideo}
				/>
				<ProgressBar
					isUploading={isUploading}
					progress={progress}
					uploadMessage={uploadMessage}
				/>
				<div className="d-flex justify-content-center gap-2">
					<ButtonSubmit
						disabled={isUploading}
						text={ isUploading ? t("loading") : t("createProject") }
					/>
				</div>
			</>
		</form>
	);
}

export default NewProjectForm;