import { useTranslation } from 'react-i18next';

function ProgressBar({ isUploading, progress, uploadMessage }) {
	const { t } = useTranslation();

	return (
		isUploading &&
			<div className="d-grid gap-2">
				<div
					className="progress"
					role="progressbar"
					aria-label="Warning example"
					aria-valuenow={progress}
					aria-valuemin="0"
					aria-valuemax="100"
				>
					<div className="progress-bar text-bg-dark" style={{ width: `${progress}%` }}>
						{progress.toLocaleString(undefined, { maximumFractionDigits: 2 })}%
					</div>
				</div>
					<p className="text-center">{t(uploadMessage)}</p>
			</div>
	);
}

export default ProgressBar;