import { useState } from 'react';

const useControlsProject = () => {
	const minSwitchTime = 3;
	const maxSwitchTime = 30;
	const maxSpeedUpSilence = 20;

	const [speedUpSilenceCheck, setSpeedUpSilenceCheck] = useState(false);
	const [speedUpAllCheck, setSpeedUpAllCheck] = useState(false);
	const [isVerticalCheck, setIsVerticalCheck] = useState(false);
	const [speedUpSilence, setSpeedUpSilence] = useState(2);
	const [speedUpAll, setSpeedUpAll] = useState(1);
	const [switchTime, setSwitchTime] = useState(minSwitchTime);

	return {
		minSwitchTime,
		maxSwitchTime,
		maxSpeedUpSilence,
		speedUpSilenceCheck,
		speedUpAllCheck,
		isVerticalCheck,
		speedUpSilence,
		speedUpAll,
		switchTime,
		setSpeedUpSilenceCheck,
		setSpeedUpAllCheck,
		setIsVerticalCheck,
		setSpeedUpSilence,
		setSpeedUpAll,
		setSwitchTime
	};
}

export default useControlsProject;
