import classNames from 'classnames';

function RadioInputValidate({
	name,
	label,
	value,
	register,
	onChange,
	errors,
	validation,
	selected,
	disabled
}) {
	const inputClassName = classNames({
		"form-check-input": true,
		"is-invalid": errors && errors[name]
	});

	return (
		<div className="form-check">
			{
				register &&
					<input
						id={`radio-${name}-${value}`}
						className={inputClassName}
						type="radio"
						name={name}
						value={value}
						checked={selected}
						onClick={onChange}
						disabled={disabled}
						// aria-invalid={errors && errors[name] ? "true" : "false"}
						{...register(name, validation)}
					/>  
			}
			{
				!register &&
					<input
						id={`radio-${name}-${value}`}
						className={inputClassName}
						type="radio"
						name={name}
						value={value}
						checked={selected}
						onClick={onChange}
						disabled={disabled}
					/>
			}
			<label
				className="form-check-label text-secondary"
				htmlFor={`radio-${name}-${value}`}
			>
				{label}
			</label>
			{
				errors && errors[name] &&
					<label
						className="text-danger invalid-feedback"
						htmlFor={`radio-${name}-${value}`}
					>
						{errors[name]?.message}
					</label>
			}
		</div>
	);
}

export default RadioInputValidate;