import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';
import InputValidate from '../../components/input/InputValidate';

import { Login } from '../../contracts/auth';

import { authActions, authLogin } from '../../store/slices/authSlice';

function LoginForm({ handleForgotPasswordModal }) {
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const loginErrors = useSelector((state) => state.auth.errorMessage);

	const onSubmit = async (data) => {
		const login_data = new Login(data.email, data.password);

		dispatch(authActions.resetErrorMessage());
		dispatch(authLogin(login_data));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="row g-3">
			<div className="col-12">
				<InputValidate
					label="Email"
					name="email"
					type="email"
					register={register}
					errors={errors}
					validation={{ required: t("emailRequired") }}
				/>
			</div>
			<div className="col-12 pass_input">
				<InputValidate
					label= {t("password")}
					name="password"
					type={showPassword ? "text" : "password"}
					value={password}
					errors={errors}
					register={register}
					required
					validation={{ required: t("passwordRequired") }}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{
					!showPassword ?
						<Eye
							className="eye_button"
							value={showPassword}
							onClick={() => setShowPassword((prev) => !prev)} 
						/>
					:
						<EyeSlash
							className="eye_button"
							value={showPassword}
							onClick={() => setShowPassword((prev) => !prev)}
						/>
				}
			</div>
			{
				loginErrors &&
					<div className="d-flex invalid-feedback" role="alert">
						{t(loginErrors)}
					</div>
			}
			<div className="d-flex justify-content-end">
				<p
					className="forgot_password_button"
					onClick={() => handleForgotPasswordModal(true)}
				>
					Забыли пароль?
				</p>
			</div>
			<div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-4">
				<ButtonSubmit text={t("login")} />
				<Link className="btn btn-lg btn-outline-info btn-width" to="/sign-up">
					{t("registration")}
				</Link>
			</div>
		</form>
	);
}

export default LoginForm;